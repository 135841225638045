import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import qs from 'qs';

export type QueryType = { [key: string]: any };
type SetQueryType = (incomingQuery: QueryType) => void;

type UseQueryParamsReturn = [value: QueryType, setValue: SetQueryType];

function removeTimeFromUrl(url: string): string {
  const urlParams = new URLSearchParams(url);
  const dateParams = ['invoicingDate', 'expiryDate'];
  const dateRanges = ['start', 'end'];

  const removeTime = (dateString: string) => `${dateString.split('T')[0]}`;

  dateParams.forEach((param) => {
    dateRanges.forEach((range) => {
      const key = `${param}[${range}]`;
      if (urlParams.has(key)) {
        const date = urlParams.get(key);
        if (date) {
          urlParams.set(key, removeTime(date));
        }
      }
    });
  });

  return `?${urlParams.toString().replaceAll('%2C', ',')}`;
}

function useQueryParams(): UseQueryParamsReturn {
  const router = useRouter();

  const query = useMemo(() => {
    if (typeof window !== 'undefined') {
      return qs.parse(removeTimeFromUrl(window.location.search), {
        ignoreQueryPrefix: true,
        comma: true,
      });
    }
    return {};
  }, [router.query]);

  const setQuery: SetQueryType = useCallback(
    (incomingQuery) => {
      const nextQuery = { ...query, ...incomingQuery };

      if (nextQuery.invoicingDate) {
        if (nextQuery.invoicingDate.start)
          nextQuery.invoicingDate.start = `${
            nextQuery.invoicingDate.start.split('T')[0]
          }`;
        if (nextQuery.invoicingDate.end)
          nextQuery.invoicingDate.end = `${
            nextQuery.invoicingDate.end.split('T')[0]
          }`;
      }
      if (nextQuery.expiryDate) {
        if (nextQuery.expiryDate.start)
          nextQuery.expiryDate.start = `${
            nextQuery.expiryDate.start.split('T')[0]
          }`;
        if (nextQuery.expiryDate.end)
          nextQuery.expiryDate.end = `${
            nextQuery.expiryDate.end.split('T')[0]
          }`;
      }

      const nextQueryString = qs.stringify(nextQuery, {
        skipNulls: true,
        arrayFormat: 'comma',
        encode: false,
      });
      router.replace({
        pathname: router.asPath.split('?')[0],
        query: nextQueryString,
      });
    },
    [query]
  );

  return [query, setQuery];
}

export default useQueryParams;
